.propell-calendar-popup {
  z-index: 1;
}

.propell-calendar-popup .react-datepicker__month-container {
  float: none;
}

.propell-calendar-popup .react-datepicker,
.propell-calendar-popup .react-datepicker__day-name,
.propell-calendar-popup .react-datepicker__day,
.propell-calendar-popup .react-datepicker__time-name,
.propell-calendar-popup .react-datepicker__current-month,
.propell-calendar-popup .react-datepicker-time__header,
.propell-calendar-popup .react-datepicker-year-header {
  color: var(--react-datepicker-font-color);
  border-radius: 2px;
}
.propell-calendar-popup .react-datepicker__day {
  border-radius: 2px;
  transition: border-radius 0.3s ease;
}

.propell-calendar-popup .react-datepicker__day:hover {
  border-radius: 4px;
  background: var(--react-datepicker-primary);
  color: var(--react-datepicker-body);
}

.propell-calendar-popup .react-datepicker__day--disabled:hover {
  background: none;
}

.propell-calendar-popup .react-datepicker__day-name,
.propell-calendar-popup .react-datepicker__day,
.propell-calendar-popup .react-datepicker__time-name {
  width: 1.5rem;
  line-height: 1.5rem;
}

.propell-calendar-popup .react-datepicker__day {
  background: var(--react-datepicker-cell-background);
}

.propell-calendar-popup .react-datepicker__day--outside-month {
  background: none;
}

.propell-calendar-popup .react-datepicker__day--disabled,
.propell-calendar-popup .react-datepicker__month-text--disabled,
.propell-calendar-popup .react-datepicker__quarter-text--disabled {
  opacity: 0.5;
}

.propell-calendar-popup .react-datepicker {
  font-size: 11px;
  font-family: var(--react-datepicker-font-family);
  border: 1px solid var(--react-datepicker-border);
  border-radius: 5px;
  background: var(--react-datepicker-body);
  transform: translateX(0);
  display: flex;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);
}

.propell-calendar-popup .react-datepicker__triangle {
  display: block;
  left: auto !important;
  transform: none !important;
}

@media (min-width: 768px) {
  .propell-calendar-popup .react-datepicker__triangle {
    left: 10px;
  }
}

.react-datepicker-popper[data-placement^='bottom'].propell-calendar-popup .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'].propell-calendar-popup .react-datepicker__triangle::before {
  top: 0;
  border-bottom-color: var(--react-datepicker-header);
}
.react-datepicker-popper[data-placement^='bottom-start'].propell-calendar-popup .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom-start'].propell-calendar-popup .react-datepicker__triangle::before {
  top: 0;
  left: 16px;
  border-bottom-color: var(--react-datepicker-header);
}
.react-datepicker-popper[data-placement^='bottom'].propell-calendar-popup .react-datepicker__triangle::after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  top: -11px;
  left: -8px;
  border-color: transparent transparent var(--react-datepicker-border) transparent;
  border-width: 9px;
  z-index: -2;
}

.react-datepicker-popper[data-placement^='bottom-start'].propell-calendar-popup .react-datepicker__triangle::after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  top: -11px;
  left: 16px;
  border-color: transparent transparent var(--react-datepicker-border) transparent;
  border-width: 9px;
  z-index: -2;
}

.propell-calendar-popup .react-datepicker__header {
  background: var(--react-datepicker-header);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 0;
}

.propell-calendar-popup .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-bottom: 1px solid var(--react-datepicker-border);
  padding-top: 15px;
  padding-bottom: 15px;
}

.propell-calendar-popup .react-datepicker__header__dropdown {
  padding: 13px 5px;
}

.propell-calendar-popup .react-datepicker__current-month {
  font-size: 13px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
}

.propell-calendar-popup .react-datepicker__day-names {
  font-size: 11px;
  height: 28px;
  background: var(--react-datepicker-background);
}
.propell-calendar-popup .react-datepicker__navigation {
  top: 15px;
  width: 24px;
  height: 24px;
  background: var(--react-datepicker-background);
  border-radius: 50%;
}
.propell-calendar-popup .react-datepicker__navigation--next {
  border-left-color: var(--react-datepicker-border);
  z-index: 2;
}
.propell-calendar-popup .react-datepicker__navigation--previous {
  border-right-color: var(--react-datepicker-border);
  z-index: 2;
}

.propell-calendar-popup .react-datepicker__navigation-icon {
  top: 0;
}

.propell-calendar-popup .react-datepicker__navigation-icon--previous {
  right: 1px;
}

.propell-calendar-popup .react-datepicker__navigation-icon--next {
  left: 1px;
}

.propell-calendar-popup .react-datepicker__navigation--previous {
  left: 10px;
}

.propell-calendar-popup .react-datepicker__navigation--next {
  right: 10px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 92px;
}

.react-datepicker-popper .react-datepicker__navigation--years-upcoming,
.react-datepicker-popper .react-datepicker__navigation--years-previous {
  top: 0;
  background: none;
  border-radius: 0;
  position: relative;
  width: 100%;
  height: 20px;
}

.react-datepicker-popper .react-datepicker__navigation--years-upcoming:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: var(--react-datepicker-font-color);
  top: 8px;
  left: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  transform: rotate(-45deg);
}

.react-datepicker-popper .react-datepicker__navigation--years-previous:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: var(--react-datepicker-font-color);
  top: 5px;
  left: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  transform: rotate(135deg);
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-width: 2px 2px 0 0;
  border-color: var(--react-datepicker-font-color);
  top: 7px;
  width: 8px;
  height: 8px;
}

.propell-calendar-popup .react-datepicker__day--selected,
.propell-calendar-popup .react-datepicker__day--in-selecting-range,
.propell-calendar-popup .react-datepicker__day--in-range {
  border-radius: 4px;
  font-weight: bold;
  background-color: var(--react-datepicker-primary);
  color: var(--react-datepicker-body);
}

.propell-calendar-popup .react-datepicker__day--range-start,
.propell-calendar-popup .react-datepicker__day--range-end {
  /*border-radius: 50%;*/
}

.propell-calendar-popup .react-datepicker__day--selected:hover {
  background-color: var(--react-datepicker-primary);
}

.propell-calendar-popup .react-datepicker__day--keyboard-selected,
.propell-calendar-popup .react-datepicker__day--keyboard-selected.react-datepicker__day--in-range,
.propell-calendar-popup .react-datepicker__month-text--keyboard-selected,
.propell-calendar-popup .react-datepicker__quarter-text--keyboard-selected {
  background: var(--react-datepicker-primary);
  color: var(--react-datepicker-body);
  font-weight: 600;
}
.propell-calendar-popup .react-datepicker__day--keyboard-selected:hover {
  background-color: var(--react-datepicker-primary);
}

.propell-calendar-popup .react-datepicker__day--keyboard-selected {
  background-color: var(--react-datepicker-cell-hover);
  /*border-radius: 50%;*/
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  top: 4px;
  right: 6px;
  width: 6px;
  height: 6px;
  border-top-color: var(--react-datepicker-cell-hover);
  border-right-color: var(--react-datepicker-cell-hover);
}

.propell-calendar-popup .react-datepicker__month-read-view--selected-month,
.propell-calendar-popup .react-datepicker__year-read-view--selected-year {
  padding-right: 14px;
  margin: 0 5px;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: var(--react-datepicker-primary);
  border-right-color: var(--react-datepicker-primary);
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type,
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.propell-calendar-popup .react-datepicker__year-dropdown,
.propell-calendar-popup .react-datepicker__month-dropdown,
.propell-calendar-popup .react-datepicker__month-year-dropdown {
  top: 40px;
}

.propell-calendar-popup .react-datepicker__year-read-view,
.propell-calendar-popup .react-datepicker__month-read-view,
.propell-calendar-popup .react-datepicker__month-year-read-view {
  visibility: visible !important;
}

.propell-calendar-popup .react-datepicker__month-option.react-datepicker__month-option--selected_month,
.propell-calendar-popup .react-datepicker__year-option.react-datepicker__year-option--selected_year {
  background: var(--react-datepicker-primary);
  color: var(--react-datepicker-body);
}

.propell-calendar-popup
  .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}

.propell-calendar-popup
  .react-datepicker__current-month
  + .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  display: none;
}

.propell-calendar-popup
  .react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown
  + .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  display: block;
}

.propell-calendar-popup .react-datepicker__year-dropdown {
  width: 35%;
  left: 50%;
  background: var(--react-datepicker-body);
}

.propell-calendar-popup .react-datepicker__month-dropdown {
  width: 45%;
  left: 18%;
  background: var(--react-datepicker-body);
}

.propell-calendar-popup .react-datepicker__year-option--selected,
.propell-calendar-popup .react-datepicker__month-option--selected {
  display: none;
}

.react-datepicker__close-icon:after {
  background-color: transparent;
  color: #828d99;
  font-size: 16px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 27px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--react-datepicker-primary);
  color: var(--react-datepicker-body);
}

.react-datepicker__time-container,
.react-datepicker__time-box {
  width: auto !important;
}
